 .hiddenStructure {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  left: -10000em !important;
}

.bx-wrapper {
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: 0;
  background: transparent;
}

.template-folder_contents .pat-structure {
  font-family: Arial, Helvetica, sans-serif !important;
}

#content,
#portal-footer {
  a:not([href=""]):not(.btn):not([role="button"]):not(.no-link):hover {
    text-decoration: underline;
    text-decoration-color: $gray;
  }
}

.portlet .portletContent > ul > li a {
  color: $gray-darker;
}

.portletNavigationTree nav.portletContent > ul > li strong {
  display: block;
  font-weight: $font-semi;
  color: $gray-darker;
  font-size: 1.2em;
  padding: $padding-large-vertical 0 0 $padding-base-horizontal;
}
#searchform .input-group {
  margin-bottom: 1em;
  input.searchPage.btn {padding: 8px 0;height: 4.1rem}
}

.pat-structure, .pat-autotoc {
  a {
    color: $gray-darker;
  }
  .itemRow.folder .title a { // fix folder-contents click problem
    position: relative;
    z-index: $zindex-dropdown - 1;
  }

} 

ul.portletContent {
  list-style: none;
  padding: 0;
}
 
.row {

  &.no-pad {
    > [class*="col-"] {
      padding: 0 !important;
    }
  }

  &.only-inner-pad {
    > [class*="col-"] {
      @media screen and (min-width: $screen-tablet) {
        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
      }
    }
  }
}

img { max-width: 100%; }
.background {
  > img {
    min-height: 100%;
    width: auto;
    max-width: none;
  }
}

.plone-modal-dialog {
  margin: 0 auto;
  max-width: 1140px;
}

.panel .panel-heading {display: block}


 

.width-100 { width: 100%; }
.height-100 { height: 100%; }

select:not([multiple]) {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background-image: url("++theme++ccap-educacio/img/select-arrow.png");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-position: calc(100% - 10px) center;
}
select::-ms-expand {
  display: none;
}

form {
  .formQuestion {
    max-width: 100%;
    display: block;
    text-align: left;
    .formHelp {
      max-width: 100%;
      word-wrap: break-word;
      display: block;
      white-space: normal;
    }
  }
  .ArchetypesBooleanWidget .formQuestion { display: inline-block; }
}

#portal-sitemap ul li {list-style: none;}

// Backend
  .configlets {
    #content-core .portlet .portletContent > & {
      padding-left: 4.0rem;
    }
    li {
      margin-bottom: .5em;
      &:after {
        display: block;
        content: '';
        clear: both;
      }
      .standalone, .link-parent, [type="submit"], button {
        background-color: transparent;
        padding: 0;
      }
    }
  }

// ONLY for small screens
@media (max-width: $screen-xs-max) {
  .pat-structure {
    table { table-layout: auto; }
    th, td { display: none; }
    .selection, .title, .actions { display: table-cell; }
    .selection { width: 10%; }
    .title {
      width: 70%;
      a {
        max-width: 92%;
        display: inline-block;
      }
    }
    .actions { width: 20%; }
  }

  #portal-footer-wrapper {
    .portlet .portletContent,
    .portlet .portletContent li {
      display: block;
      text-align: center;
    }

    .info {
      text-align: center;
      margin-bottom: 2em;
      > * {
        margin-bottom: .25em;
      }
    }

    .social-links {text-align: center;}
  }
  
  .form-wrapper {
    .pattern-pickadate-wrapper,
    .pattern-pickadate-date-wrapper,
    .pattern-pickadate-date.picker__input {
      max-width: none;
      width: 100%;
    }
    .pattern-pickadate-date.picker__input {
      margin: 0 0 .2em 0;
    }
  }
  .autotabs .autotoc-nav {
    width: 100%;
    margin: 0;
    max-height: none;
    overflow: hidden;
    white-space: normal;
    
    #sorting-options {
      display: flex;
      text-align: center;
      padding: 0 0 1em 0;
      flex-wrap: nowrap;
    }
  }
}
@media(min-width:$screen-sm-min){}
@media(min-width:$screen-md-min){}
@media(min-width:$screen-lg-min){}