@import 'custom-variables';
@import 'bootstrap/stylesheets/bootstrap.scss';
@import 'custom-mixins';
@import 'plone-fixes';
@import 'app/app';
@media screen { @import 'custom'; }

// Modules
// @import "./../../modules/bxslider/jquery.bxslider";
@import "./../../modules/cookiebar/cookiebar";

