// Border classes inspired by boostrap 4

// ==== Mixins
    @mixin add-borders ($query: '', $amount: 0, $borderColor: $gray-lighter, $nameColor: '') {
        .b#{$query}-#{$amount}#{$nameColor} {
            $remAmount: ($amount / 10) * 1rem;
            border: $remAmount solid $borderColor;
        }
        .bt#{$query}-#{$amount}#{$nameColor} {
            $remAmount: ($amount / 10) * 1rem;
            border-top: $remAmount solid $borderColor;
        }
        .br#{$query}-#{$amount}#{$nameColor} {
            $remAmount: ($amount / 10) * 1rem;
            border-right: $remAmount solid $borderColor;
        }
        .bb#{$query}-#{$amount}#{$nameColor} {
            $remAmount: ($amount / 10) * 1rem;
            border-bottom: $remAmount solid $borderColor;
        }
        .bl#{$query}-#{$amount}#{$nameColor} {
            $remAmount: ($amount / 10) * 1rem;
            border-left: $remAmount solid $borderColor;
        }
    }

// === No border
    .b-0 {
        border: 0 !important;
    }
    .bt-0 {
        border-top: 0 !important;
    }
    .br-0 {
        border-right: 0 !important;
    }
    .bb-0 {
        border-bottom: 0 !important;
    }
    .bl-0 {
        border-left: 0 !important;
    }

// === Rounded borders
    .rounded {
        border-radius: $border-radius-base;
    }
    .rounded-top {
        border-radius: $border-radius-base $border-radius-base 0 0;
    }
    .rounded-right {
        border-radius: 0 $border-radius-base $border-radius-base 0;
    }
    .rounded-bottom {
        border-radius: 0 0 $border-radius-base $border-radius-base;
    }
    .rounded-left {
        border-radius: $border-radius-base 0 0 $border-radius-base;
    }
    .rounded-circle {
        border-radius: 50%;
    }
    .rounded-0 {
        border-radius: 0 !important;
    }

// === Colored borders
    .border-black {
        border-color: $gray-darker !important;
    }
    .border-semi {
        border-color: rgba($white, 0.50) !important; 
    }
    // ==== All sizes
    @include add-borders('', 1, $gray-lighter, '');
    @include add-borders('', 1, $white, '-white');

    // ==== Extra small screen / phone
    @media screen and (max-width: $screen-xs-max) {
        .b-xs-0 {
            border: 0 !important;
        }
        .bt-xs-0 {
            border-top: 0 !important;
        }
        .br-xs-0 {
            border-right: 0 !important;
        }
        .bb-xs-0 {
            border-bottom: 0 !important;
        }
        .bl-xs-0 {
            border-left: 0 !important;
        }
        @include add-borders('-xs', 1, $gray-lighter, '');
        @include add-borders('-xs', 1, $white, '-white');
    }
    // ==== Small screen / tablet 
    @media screen and (min-width: $screen-sm-min) {
        .b-sm-0 {
            border: 0 !important;
        }
        .bt-sm-0 {
            border-top: 0 !important;
        }
        .br-sm-0 {
            border-right: 0 !important;
        }
        .bb-sm-0 {
            border-bottom: 0 !important;
        }
        .bl-sm-0 {
            border-left: 0 !important;
        }
        @include add-borders('-sm', 1, $gray-lighter, '');
        @include add-borders('-sm', 1, $white, '-white');
    }
    // ==== Medium screen / desktop
    @media screen and (min-width: $screen-md-min) {
        .b-md-0 {
            border: 0 !important;
        }
        .bt-md-0 {
            border-top: 0 !important;
        }
        .br-md-0 {
            border-right: 0 !important;
        }
        .bb-md-0 {
            border-bottom: 0 !important;
        }
        .bl-md-0 {
            border-left: 0 !important;
        }
        @include add-borders('-md', 1, $gray-lighter, '');
        @include add-borders('-md', 1, $white, '-white');
    }
    // ==== Large screen / wide desktop    
    @media screen and (min-width: $screen-lg-min) {
        .b-lg-0 {
            border: 0 !important;
        }
        .bt-lg-0 {
            border-top: 0 !important;
        }
        .br-lg-0 {
            border-right: 0 !important;
        }
        .bb-lg-0 {
            border-bottom: 0 !important;
        }
        .bl-lg-0 {
            border-left: 0 !important;
        }
        @include add-borders('-lg', 1, $gray-lighter, '');
        @include add-borders('-lg', 1, $white, '-white');
    }