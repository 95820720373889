/* #######################################
    TOC

    1 - BODY
	2 - HEADER
		2.0 - Logo
        2.1 - Searchbox
		2.2 - Main menu
		2.3 - Actions top
    3 - HOME
        3.1 - Slider
        3.2 - Announcements
        3.3 - News
		3.4 - Emissions
		3.5 - Icons
		3.6 - Banners
		3.7 - Interest links
    4 - FOOTER
        4.1 - Icon Banners Featured
        4.2 - Skyline
		4.3 - Footer menu
		4.4 - Standars
	5 - INTERIOR
		5.1 - Left navigation
		5.2 - Document actions
		5.3 - Related document
	6 - BACK
	7 - OTHER
#######################################    */

// #######################################
// 1 - BODY
body {
	background: linear-gradient(to bottom, $gray-light-medium, $white 60%) no-repeat;
}
// folder contents

.template-folder_contents {

	#main-container table,
	#main-container td,
	#main-container tr,
	#main-container th {
    	border: 1px solid #ddd;
    }
}

// #######################################

// #######################################
// 2 - HEADER

// 2.0 - Logo
#portal-logo {
	display: flex; //block;
	max-width: 27.0rem;
	margin-top:1.6rem;
}

// 2.1 - Searchbox
.modernSearch {
	background-color: $gray-lighter;
	display: flex;
	border-radius: .7rem;
	padding: 1.0rem 1.0rem .5rem 1.0rem;
	position: relative;
	input[type="text"] {
		border: none;
		background-color: transparent;
		padding: 0;
		height: 2.2rem;
		font-size: $font-size-base;
	}
	input[type="submit"] {
		background-color: transparent;
		text-indent: -1000em;
		border: none;
		height: 2.2rem;
		width: 2.2rem;
		position: absolute;
		right: 1.0rem;
	}
}


@media (max-width: $screen-sm-max) {
	.actionMenu {
	    float: none !important;
	}

	.searchPage .actionMenu dt{
	    position: relative !important;
	}
}


// 2.2 - Main menu

body{

	&.plone-toolbar-left-expanded{
		#main-navigation.affix{
			padding-left: 120px;
		}
	}
	&.plone-toolbar-left-default{
		#main-navigation.affix{
			padding-left: 60px;
		}
	}
}

.mainNav {
	font-size: 1.3rem;
	&__list {
		display: flex;
		flex-direction: column;
		&:after {
			content: none;
		}
	}
	a {
		color: $gray-dark;
		&:hover,
		&:focus {
			text-decoration: none;
			color: $brand-primary;
		}
		span {
			display: block;
			padding: 0 1.5rem;
		}
	}
	&:after {
		content: none;
	}
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		font-size: 1.0rem;
		.nav:after, .nav:before {
			content: none;
		}
		.navbar-nav {
			align-items: center;
			& > li {
				flex: 1 1 auto;
				position: relative;
				& > a {
					padding: 1.6rem 0;
					display: block;
					span {
						padding: 0 .8rem;
					}
				}
				&.active a:after {
					transform: translateX(-50%) scaleX(1);
				}
				& + li span:before {
					content: '';width: 1px; height: 1em; background-color: $gray-light-medium;display: block;
					position: absolute; left: 0;top: 1.8rem;
				}
			}
			a {
				&:after {
					content: '';
					position: absolute;
					bottom: 0.8rem;
					left: 50%;
					transform: translateX(-50%) scaleX(0);
					width: 4.5rem;
					height: .4rem;
					border-radius: .2rem;
					transition: transform $transition-fast ease;
					transform-origin: center center;
					background-color: $brand-primary;
				}
				&:hover:after {
					transform: translateX(-50%) scaleX(1);
				}
			}
		}
	}
	@media (min-width: $screen-lg-min) {		// 1200 pixels - 75em
		font-size: 1.2rem;
		&__list {
			flex-direction: row;
			justify-content: space-around;
		}
		&__link {
			transition: color $transition-fast ease-in;
			display: block;
			position: relative;
			padding: .9em 0;
			&:after {
				content: '';
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				right: 0;
				height: .5rem;
				background-color: $brand-primary;
				transition: transform $transition-fast ease-in;
				transform: scale(0, 1);
				transform-origin: left center;
			}
			&:hover,
			&:focus,
			.currentItem & {
				color: $brand-primary;
				&:after {
					transform: scale(1, 1);
				}
			}
		}
	}
}
#main-navigation {
	&.affix {
		left: 0;
	}
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		&.collapse {
			display: block;
			height: auto !important;
		}
	}
}

.affix {
	box-shadow: 0 0 .4rem rgba($gray-base, .2);
}

.affixHack {
	height: 6.1rem;
	display: none;
	.affix+& {
		display: block;
	}
}

// 2.3 - Actions top
#portal-actions-top {
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		.list-dashed {
			justify-content: flex-end;
		}
	}

}

// #######################################


// #######################################
// 3 - HOME
.template-portada-view #content {
	margin-bottom: 0;
}
// 3.1 - Slider
.portalSlider__item{width: 100%;}

.portalSlider {
	position: relative;
	&__image {
		height: 30.0rem;
	}
	/* ---------------------- */
	// .owl-carousel.owl-drag .owl-item {
	// 	touch-action: none;
	// }
	.owl-nav {
		display: none;
	}
	.owl-dots {
		position: absolute;
		bottom: 0;
		left: 50%;
		right: auto;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		padding: .8rem;
		background-color: rgba($white, .75);
		border-radius: 1.8rem 1.8rem 0 0;
		text-align: center;
		&>* {
			margin: 0 .5rem;
		}
	}
	.slideNavigation {
		button {
			position: absolute;
			z-index: 1;
			left: 0;
			top: -17.1rem;
			& + button {
				right: 0;left: auto;
			}
			&.disabled {
				opacity: .5;
				cursor: default;
				&:hover{
					background-color: transparent;
					border:none;
					transform: none;
					color: $white;
				}
			}
			&:hover, &:focus {
				color: $brand-primary;
				outline: none;
			}
		}
	}
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		&__image {
			height: 50.0rem;
		}
		.slideNavigation {
			button {
				top: -27.1rem;
			}
		}
	}
}

.owl-dot {
	span {
		display: block;
		text-indent: -100em;
		width: 1.8rem;
		height: 1.8rem;
		border: .3rem solid $gray-darker;
		border-radius: .9rem;
		background-color: transparent;
		overflow: hidden;
	}
	&:hover span {
		background-color: $brand-primary;
	}
	&.active span {
		background-color: $brand-primary;
	}
}

.owl-nav {
	button.disabled {
		opacity: .33;
		cursor: default !important;
		&:hover {
			transform: none !important;
		}
	}
}

.owl-next,
.owl-prev {
	transition: transform .15s ease-in;
	background-color: transparent;

	&:hover,
	&:focus {
		transform: scale(1.2);
		background-color: transparent;
	}
}

// 3.2 - Announcements
#owlAnnouncementsContainer {
	button {
		padding: 0;
		&+button {
			margin-left: .25em;
		}
	}
}

// 3.3 - News
.newsItem {
	border: 1px solid $gray-light-medium;
	position: relative;
	transition: background-color $transition-fast ease;
	min-height: 20.0rem;
	&__image {
		height: 18.0rem;
	}
	&__link:focus, &__link:hover,  {
		background-color: $link-hover-color;
		color: $white !important;
		text-decoration: none;
	}
	&--video {
		height: 52.73vw;
	}

	.socialShare {
		position: absolute;
		top: 0;
	}
	@media (min-width: $screen-sm-min) { //768px
		&--video {
			height: 42.2rem;
		}
		&--featured {
			&__image {
				width: 100%;
				height: 42.1rem;
				overflow: hidden;
			}
		}
	}
	@media (min-width: $screen-md-min) { //992px
		&--video {
			height: 25.6rem;
		}
		&--featured {
			&__image {
				height: 20.0rem;
			}
		}
	}
	@media (min-width: $screen-lg-min) { //1200px
		&--video {
			height: 100%;
		}
	}
}

.reloadContent+.reloadContent,
.reloadContent .visible-block {
	.reloadContent__item {
		animation-name: animTabs;
		animation-duration: $transition-fast*2;
		opacity: 0;
		animation-fill-mode: forwards;
	}
	.reloadContent__child:nth-child(3n) .reloadContent__item {
		animation-delay: .2s;
	}
	.reloadContent__child:nth-child(3n+1) .reloadContent__item {
		animation-delay: .2s*2;
	}
	.reloadContent__child:nth-child(3n+2) .reloadContent__item {
		animation-delay: .2s*3;
	}
}

.socialShare {
	display: flex;
	background-color: $gray-lighter;
	&__button {
		background-color: $brand-primary !important;
	}
}

// 3.4 - Emissions
.imagePollution {
	background: url('++theme++lipasam/img/image-contador.jpg') no-repeat center center / cover $gray-darker;
	min-height: 30.0rem;
	position: relative;
	h3 {
		min-height: 2.50em;
		margin-bottom: 15.8rem;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background-color: $gray-darker;
		opacity: .7;
		top: 0;right: 0;bottom: 0;left: 0;
		z-index: 0;
	}

}
.customIconPollution {
	fill: #94bb20;
	height: 17.4rem;
	width: 17.4rem;
	border-radius: 8.7rem;
	background: #FFFFFF center;
	position: relative;
	display: block;
	padding: 2em;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin: 3.5rem auto -1.8rem auto;
	top: -158px;
	}

.articlePollution {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.textPollution {
	margin-top: -3.3rem;
	position: relative;
	z-index: 1;
}
.br-20 {
	border-radius: 20px;
}
.wrapperNewsEmissions {
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		display: flex;
		#portal-emissions {
			display: flex;
			flex-direction: column;
			height: 100%;
			.textPollution {
				flex: 1 0 auto;
			}
		}
	}
}
#owlContadorContainer {
	position: relative;
	button, a {
		position: absolute;
		top: 26.5rem;
		z-index: 10;
		&.owl-prev {
			left: 0;
		}
		&.owl-next {
			right: 0;
		}
		&:focus {
			outline: none;
		}
	}
}

// 3.5 - Icons
.homeLinks {
	&__link {
		color: $gray-darker;
		flex-wrap: nowrap !important;
		transition: flex $transition-fast ease;
		&:hover {
			text-decoration: none !important;
			color: $brand-secondary;
			svg {
				fill: $brand-secondary !important;
				animation: counterBouncing .5s;
			}
		}
	}
	&__title {
		flex-shrink: 1;
	}
}

// 3.6 - Banners
#asideBanners {
	display: flex;
	flex-wrap: wrap;

	& > * {
		flex: 1 0 32.0rem;
		display: block;
	}
	a {
		pointer-events: auto;
	}
	img {
		height: auto;
	}
	.visible-1400 {
		display: none;
	}
	@media (min-width: 1400px) {
		pointer-events: none;
		z-index: $zindex-flat;
		width: 100%;
		position: fixed;
		left: 50%; top:0;
		transform: translateX(-50%);
		flex-wrap: nowrap;
		justify-content: center;
		body.plone-toolbar-left-expanded & {
			width: calc(100% - 12.0rem);
			left: calc(50% + 12.0rem);
			transform: translateX(calc(-50% - 6.0rem));
		}
		&.topFixed {
			top: 5.0rem;
		}
		& > * {
			flex: 0 1 25.5rem;
		}
		img {
			height: auto;
		}
		.banner-left {
			margin-right: 116.8rem;
		}
		.visible-1400 {
			display: inline;
		}
		.hidden-1400 {
			display: none;
		}
	}
}

//	3.7 - Interest links
	#portal-interest-links {
		.get-more-items {
			color: $white;
			background-color: transparent;
			border:none;
			&:hover, &:hover {
				color: $gray-darker;
			}
		}
	}

// #######################################
// #######################################
// 4 - FOOTER
// 4.1 - Icon Banners Featured
.footerBanners {
	&__item {
		flex: 1 1 100%;
	}
	&__link {
		background-color: $gray-darker;
		height: 26.0rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: all $transition-fast ease-in !important;
		&:hover,
		&:focus {
			text-decoration: none;
			background-color: $white;
			color: $gray-darker !important;
			.footerBanners__figure {
				background-color: rgba($brand-primary, .75);
			}
		}
		&:active {
			.footerBanners__figure {
				transform: scale(0.9);
			}
		}
	}
	&__figure {
		transition: background-color $transition-fast ease-in;
		border: .2rem solid $white;
		border-radius: 5.0rem;
		width: 10.0rem;
		height: 10.0rem;
		padding: .7rem;
		margin: 0 auto 1.4rem auto;
		display: flex;
	}
	&__svg {
		margin: auto;
		fill: $white;
	}
	&__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.30;
		z-index: 0;
	}
}

#owlBannersContainer {
	font-size: 1.8rem;
	button {
		padding: 0;
	}
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		font-size: 3.0rem;
	}
}

// 4.2 - Skyline
#portal-footer-wrapper {
	background-color: $brand-primary;
	padding: 4.5rem 0 3.0rem 0;
	margin-top: 14.0rem;
	position: relative;
	&:before {
		content: url('/++theme++lipasam/img/skyline-verde.svg');
		display: block;
		height: 4.5rem;
		width: 32.0rem;
		position: absolute;
		top: -4.1rem;
		left: 50%;
		transform: translateX(-50%);
	}
	@media (min-width: $screen-sm-min) { //768px
		&:before {
			height: 12.1rem;
			width: 75.0rem;
			top: -9.0rem;
		}
	}
	@media (min-width: $screen-md-min) { //992px
		&:before {
			width: 88.0rem;
			height: 15.0rem;
			top: -11.0rem;
		}
	}
}

// 4.3 - Footer menu
@media (min-width: $screen-sm-min) {
	//768px
	.footerMenu {
		ul {
			display: flex;
			justify-content: center;
		}
		li {
			&+li {
				margin-left: 1em;
				padding-left: 1em;
				border-left: 1px solid rgba($white, 0.75);
			}
		}
	}
}

@media (min-width: $screen-md-min) {	//992px
	.groupActionsRRSS {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#footer-contact {
		display: flex;
		align-items: center;
		border-left: .1rem solid $white;
	}
}

// 4.4 - Standars
#standars {
	ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

// #######################################

// #######################################
//	5 - INTERIOR

	.downBar {
		&:after {
			content: '';
			display: block;
			background-color: $gray-light-medium;
			width: 6.5rem; height: .5rem;
			margin-top: .5em;
		}
	}
// 5.1 - Left navigation
#sidebar {
	.portletWrapper {
		background-color: transparent;
		padding: 0;
	}
	.portlet {
		border: none;
		border-radius: 0;
		box-shadow: none;
		margin-bottom: 0;
		& > :last-child {
			border-radius: 0;
		}
	}
	.portletNavigationTree {
		position: relative;
		text-transform: uppercase;
		padding-bottom: 2.6rem;
		.portletContent {
			font-size: 1.3rem;
			background-color: $gray-lighter;
			padding: 1.2rem 1.5rem;
			& > ul li:hover, & > ul li:focus {
				background-color: transparent;
			}
			& > ul ul {
				padding-left: 1.5rem;
				text-transform: none;
				border-bottom: .1rem solid $gray-light-medium;
			}
			a:hover, &:focus {
				color: $brand-secondary;
				background-color: transparent;
				text-decoration: none;
				&::after {
					content: none;
					display: none;
				}
			}
			& > ul > li {
				& > a {
					background-color: transparent;
					border-bottom: .1rem solid $gray-light-medium;
					border-top: none;
					transition: color $transition-fast ease-in;
					padding-left: 0;
					&.navTreeCurrenItem {
						color: $brand-primary;
					}
				}
				&.navTreeCurrentNode > a {
					border-bottom-color: $brand-primary;
				}
				&:last-child > a {
					border-bottom: 0;
				}
				& > ul{
					a {
						padding: 0.5rem;
					}
					li > a {
						border-bottom: none;
						border-top: 0;
					}
				}
			}
			.navTreeTopNode {
				color: $brand-primary;
				text-transform: uppercase;
				font: $font-bold 2.5rem / 1.25 $headings-font-family;
				border-bottom: .2rem solid $brand-primary;
				a {
					color: $brand-primary;
					padding: 0;
					border-top: 0;
					&:hover, &:focus {
						text-decoration: none;
					}
					&:before { content: none; }
				}
			}
			a.navTreeCurrentItem {
				font-weight: $font-semi;
				background-color: transparent;
				border-bottom: .1rem solid $brand-primary;
				&:after {
					content: none;
					display: none;
				}

			}
		}
		/* Triangle peu */
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0; left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 2.6rem 4.6rem 0 0;
			border-color: $gray-lighter transparent transparent transparent;
		}
	}
	.section-parent-title {
		color: $brand-gray
	}
}

// 5.2 - Document actions
	.actionIcon {
		$icoMida: 3.8rem;
		border: 1px solid currentColor;
		height: $icoMida; width: $icoMida;
		border-radius: ($icoMida / 2);
		line-height: $icoMida;
		display: inline-block;
		text-align: center;
	}
//5.3 - Related document
.downBar:after { // que la barra sea más grande

    width: 15.5rem;

}


// #######################################

// 	6 - BACK
#login-form .formControls .context{
	font-size: 15px;
	color: white;
	background-color: $brand-primary;
	border-width: 1;
	border-color: black;

}

// 7 - OTHERS
#relatedImages .owl-dots {
	text-align: center;
}
/* evitar que salgan las lineas grises en las tablas*/
#main-container{
	table,td,tr,th{
		border:0px;
	}
	table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
    border: none;
	}

}

.table-bordered {
	border: 1px solid #ddd !important;
	td {
		border: 1px solid #ddd !important;
	}
}