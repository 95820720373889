// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent} {
    transition: color .25s ease;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    @if (lightness($color) > 50) {
      color: $link-hover-color; // darken($color, 20%); // dark color lighter effect
    } @else {
      color: $link-hover-color; // lighten($color, 20%);  // light color darker effect
    }
  }
}
