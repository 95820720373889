// Position classes
@mixin setPositions ($query: '') {
    .pos#{$query}-relative {
        position: relative !important;
    }
    .pos#{$query}-absolute {
        position: absolute;
    }
    .abs#{$query}-t, .abs#{$query}-t-r, .abs#{$query}-t-l {
        position: absolute;
        top:0;
    }
    .abs#{$query}-r, .abs#{$query}-t-r, .abs#{$query}-b-r {
        position: absolute;
        right:0;
    }
    .abs#{$query}-b, .abs#{$query}-b-r, .abs#{$query}-b-l {
        position: absolute;
        bottom:0;
    }
    .abs#{$query}-l, .abs#{$query}-b-l, .abs#{$query}-t-l {
        position: absolute;
        left:0;
    }
    .abs#{$query}-center-h {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .abs#{$query}-center-v {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .abs#{$query}-expanded {
        position: absolute;
        left: 0; right: 0;
        @media screen and (min-width: $screen-sm-min) {
            .plone-toolbar-expanded & {
                left: 12.0rem;
            }
        }
    }
    .fixed#{$query} {
        position: fixed; left: 0; top:0;
        z-index: $zindex-modal-background - 1;
    }
    .static#{$query} {
        position: static;
        left: auto; top: auto; right: auto; bottom: auto;
    }
    .abs#{$query}-vertical-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }
    .abs#{$query}-horizontal-center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%)
    }
    .abs#{$query}-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
}
// ==== All screens
        @include setPositions('');
// ==== Extra small screen / phone
    @media screen and (max-width: $screen-xs-max) {
        @include setPositions('-xs');
    }
// ==== Small screen / tablet 
    @media screen and (min-width: $screen-sm-min) {
        @include setPositions('-sm');
    }
// ==== Medium screen / desktop
    @media screen and (min-width: $screen-md-min) {
        @include setPositions('-md');    
    }
// ==== Large screen / wide desktop    
     @media screen and (min-width: $screen-lg-min) {
        @include setPositions('-lg');   
    }    

// ==== Z-index classes
    .z-index-negative {
        z-index: $zindex-negative          ;
    }
    .z-index-flat {
        z-index: $zindex-flat              ;
    }
    .z-index-navbar {
        z-index: $zindex-navbar            ;
    }
    .z-index-dropdown {
        z-index: $zindex-dropdown          ;
    }
    .z-index-popover {
        z-index: $zindex-popover           ;
    }
    .z-index-tooltip {
        z-index: $zindex-tooltip           ;
    }
    .z-index-navbar-fixed {
        z-index: $zindex-navbar-fixed      ;
    }
    .z-index-modal-background {
        z-index: $zindex-modal-background  ;
    }
    .z-index-modal {
        z-index: $zindex-modal             ;
    }