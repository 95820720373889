//
// Grid system
// --------------------------------------------------
@mixin clearPseudo() {
  @supports (display: flex) or (display: -webkit-box) {
    &:before, &:after {
      content: none;
    }
  }
}

// Equal height columns 
.eq-height, .visible-flex {
    display: flex;
    flex-wrap: wrap;
    & > [class*='col-'], .eq-height article {
      display: flex;
      flex-direction: column;
  }
  &--center {
    justify-content: center;
  }
  &--middle {
    align-items: center;
  }
}
.flex-nowrap {
  flex-wrap: nowrap;
}


// Distributed columns quantity independent
.distributed {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  &--140 > div {
    flex: 1 0 14.0rem;
  }

}

.display-flex {
  @include clearPseudo;
  display: flex;
}
.flex-spacebetween {
  justify-content: space-between;
  &:after, &:before {
    content: none;
  }
}
.flex-aligncenter {
  align-items: center;
}
.flex-alignbaseline {
  align-items: baseline;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-shrink {
  flex-shrink: 0;
}
.flex-100 {
  flex: 0 0 100%;
}