// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
  position: relative;
  padding: 0;
  @include navbar-vertical-align(22px);
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  min-height: 2.8rem;
  min-width: 2.6rem;
  border: none;
 

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
    background-color: transparent !important;
  }
  &:hover, &:active {
    background-color: transparent !important;
  }

  // Bars
  .icon-bar, .menu-bar, .menu-bar:before, .menu-bar:after {
    display: block;
    width: 2.6rem;
    height: 0.6rem;    
  }
  .menu-bar, .menu-bar:before, .menu-bar:after {
    transition: transform 0.25s ease-in-out, background-color 0.2s ease-in-out;
    position: absolute;    
  }
  .menu-bar {
    &:before, &:after {
      content: ' ';
    }
    &:before {
      top: 0;
      transform: rotate(45deg);
    }
    &:after {
      bottom:0;
      transform: rotate(-45deg);
    }    
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }
  &.collapsed {
    .menu-bar:before, .menu-bar:after {
      transform: rotate(0);
    }
    .menu-bar::before {
      top: -1.0rem;
    }
    .menu-bar:after {
      bottom: -1.0rem;
    }
  }

  @media (min-width: $screen-lg-min) {
    display: none;
  }
}

// Darken the responsive nav toggle
  .navbar-toggle {
    &.collapsed .menu-bar, .menu-bar:before, .menu-bar:after {
      background-color: $gray-darker;
    }
    .menu-bar {
      background-color: transparent;
    }
    &--white, .portaltype-minisite & {
      &.collapsed .menu-bar, .menu-bar:before, .menu-bar:after {
        background-color: $white;
      }
    }
  }
  
// Animated TABS
  @keyframes animTabs {
    from { opacity: 0;}
    to   { opacity: 1;}
  }
  .tab-content {
    & > .tab-pane {
       
      &.active {
        animation-name: animTabs;
        animation-duration: $transition-fast*2;
      }
    }
  }